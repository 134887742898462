@import './vars';


.socials-mobile {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  height: 80px;
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: center;
  padding: 0 20px;

  @include desktop {
    background-color: transparent;
  }
}

.social-button {
  background-color: #fff;
  width: 33px;
  height: 33px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}