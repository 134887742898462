@import './vars';
.faq {
  background-color: #fff;
  &-card {
    padding: 15px 18px;
    @include desktop {
      padding: 30px 40px;
    }
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 9px;
      padding-bottom: 15px;
      cursor: pointer;
      span {
        width: 80%;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        color: #000000;
        margin: 0;
        @include desktop {
          font-size: 16px;
        }
      }
      &__arrow {
        transition: all .3s ease-out;
        border: 4px solid #E43F40;
        transform: rotate(45deg);
        width: 16.4px;
        height: 16.4px;
        border-top: 0;
        border-left: 0;
      }
    }
    .content {
      max-height: 0px;
      overflow: hidden;
      transition: max-height 0s ease-in-out;
      p {
        width: 80%;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        margin: 10px 0 0;
        color: #5B5B5B;
        display: inline-block;
      }
    }
    .divider {
      width: 100%;
      height: 1px;
      background-color: #DCDCDC;
      margin: 25px 0 0;
    }
    &--active {
      .header {
        &__arrow {
          transform: rotate(-135deg);
        }
      }
      .content {
        max-height: 200vh;
      transition: max-height .9s ease-in-out;
      }
    }
  }
}