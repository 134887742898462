@import "../../styles/config/abstracts/mixins";

.crypto-content_steps {
  padding-block: 3rem;

  @include mediaQueriesLg {
    padding-block: 1rem;
  }

  &.two {
    background-color: #E4EEF5;
  }

  .crypto-step {
    @include mediaQueriesLg {
      padding: 2rem;
    }

    &_header {
      display: flex;
      align-items: center;
      gap: 1rem;

      h3,
      span {
        font-size: 20px;
        font-weight: 700;
        line-height: 32.68px;
        color: #000000;
        margin: 0;
        margin-bottom: 2rem;

        @include mediaQueriesMd {
          font-size: 16px;
          line-height: 20px;
        }
      }

      span {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: #FFFFFF;
        border-radius: 37px;
        width: 30px;
        height: 30px;
        background-color: #E43F40;
      }
    }

    &_content {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      p {
        color: #000000;
        font-size: 18px;
        font-weight: 400;
        line-height: 24.51px;
      }

      &__fill {
        color: #FF0000 !important;
      }
    }

    &_cards {
      margin-top: 3rem;

      @include mediaQueriesLg {
        padding: 2rem;
        margin-top: 0;
      }

      .cards-one,
      .cards-two,
      .cards-three {
        list-style: none;
        padding: 0;
      }

      .cards-one {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(470px, 1fr));

        max-width: 1000px;
        margin: auto;
        gap: 1rem 3rem;

        @include mediaQueriesLg {
          grid-template-columns: 1fr;

        }

        .list-item {
          background-color: #E4EEF5;
          border-radius: 22px;
          padding: 1rem;
        }

        .list-subitem {
          list-style: disc;

          li {
            color: #000000;
            font-size: 16px;
            font-weight: 400;
            line-height: 21.79px;
          }
        }
      }

      .cards-two {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));

        max-width: 1200px;
        margin: auto;
        gap: 1rem;

        h4 {
          margin: 0;
        }

        .list-item {
          background-color: #FFF;
          border-radius: 22px;
          padding: 1rem;
        }
      }

      .cards-three {
        display: flex;
        justify-content: space-between;
        max-width: 1200px;
        margin: auto;

        background-color: #E43F40;
        border-radius: 15px;
        padding: 20px;
        margin-bottom: 3rem;

        @include mediaQueriesLg {
          flex-direction: column;
          align-items: center;
          margin-bottom: 0;

          img {
            display: none;
          }
        }

        h4 {
          margin: 0;
        }

        .list-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 2rem;


          padding: 1rem;
          max-width: 230px;
          text-align: center;

          .dots {
            display: flex;
            justify-content: center;
            align-items: center;

            background-color: #292B2C;
            color: #FFFFFF;

            font-size: 24px;
            font-weight: 700;
            line-height: 32.68px;
            text-align: center;

            width: 40px;
            height: 40px;
            border-radius: 100%;

          }

          h4 {
            color: #FFFFFF;
            font-size: 16px;
            font-weight: 600;
            line-height: 24.51px;
          }
        }
      }
    }
  }

  .footer-step-three {
    p {
      color: #000000;
      font-size: 18px;
      font-weight: 400;
      line-height: 24.51px;
    }

    @include mediaQueriesMd {
      padding: 0 2rem;
    }
  }
}