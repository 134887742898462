@import './vars';

.container {
  padding: 15px 9px;
  @include desktop {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    padding: 0 20px;
    justify-content: space-between;
  }
  .content {
    @include desktop {
      width: calc(100% - 331px);
    }
    @media screen and (min-width: 999px) {
      width: calc(100% - 400px);
    }
    .menu {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      background-color: #fff;
      &-item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25%;
        height: 57px;
        cursor: pointer;
        &--active {
          background: #C84243;
        }

        span {
          font-weight: 700;
          font-size: 12px;
          line-height: 16px;
          align-items: center;
          text-align: center;
          color: #1C1E1F;
          text-transform: uppercase;
          max-width: 67px;
          @include desktop {
            font-size: 15px;
          }
        }
      }
    }
    .divider {
      width: 100%;
      height: 20px;
      background-color: #FF0000;
      &--line {
        height: 1px;
        background-color: #DCDCDC;
        margin: 9px 0;
      }
    }
    .dynamic-container {
      .title {
        background-color: #fff;
        padding: 15px 18px;
        h2 {
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 700;
          font-size: 21px;
          line-height: 29px;
          margin: 0;
          color: #000000;
          text-transform: capitalize;
        }
        &.uppercase {
          h2 {
            text-transform: uppercase;
          }
        }
      }
    }
  }

  .side-bar {
    width: 100%;
    min-height: 200px;
    @include desktop {
      width: 300px;
    }
    @media screen and (min-width: 999px) {
      width: 369px;
    }
  }
}