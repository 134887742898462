@import './vars';

.auth-container {
  padding: 8px 13px;
  background-color: $color-red-primary;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 64px;
  @include desktop {
    padding: 0;
    height: 80px;
    background-color: $color-black-primary;
  }
}