@import './vars';
  .shadow {
    width: 100%;
    height: 90px;
    background: rgb(204,204,204);
    background: linear-gradient(0deg, rgba(204,204,204,0.913624824929972) 51%, rgba(204,204,204,0.4150253851540616) 80%);
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 0;
  }
.markets {
  width: 100%;
  padding: 0;
  position: relative;
  @include desktop {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .card {
    width: 100%;
    background-color: #fff;
    margin-bottom: 1.3rem;
    position: relative;
    &.last {
      margin-bottom: 0;
    }
    .content {
      padding: 1.3rem;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      flex-wrap: wrap;
      .team {
        width: calc(50% - 45px);
        &__logo {
          width: 100%;
          height: 115px;
          display: flex;
          align-items: center;
        }
        img {
          width: 70%;
          max-width: 89px;
          display: block;
          margin: 0 auto;
        }
        span {
          font-size: 14px;
          color: rgb(119, 119, 119);
          line-height: 1.7em;
          font-weight: 400;
          display: block;
          margin: 0 auto;
          text-align: center;
        }
      }
    }
    .footer {
      background-color: #ccdee4;
      text-align: center;
      font-size: 1.3em;
      color: #242427;
      font-weight: 700;
      padding: 0.4em 0;
      width: 100%;
      bottom: 0;
      left: 0;
    }
  }
  .pagination {
    width: 100%;
    background-color: #ccdee4;
  }
}