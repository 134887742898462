.margin\:0 {
  margin: 0;
}

.padding\:0 {
  padding: 0;
}
@for $i from 1 through $for-length {
  $results: #{$i * 2};
  @include makerSpaces($breakXl, $results);
}

@include mediaQueriesMd() {
  .md\:margin\:0 {
    margin: 0;
  }
  .md\:padding\:0 {
    padding: 0;
  }
  @for $i from 1 through $for-length {
    $results: #{$i * 2};
    @include makerSpaces($breakMd, $results);
  }
}

@include mediaQueriesSm() {
  @for $i from 1 through $for-length {
    $results: #{$i * 2};
    @include makerSpaces($breakSm, $results);
  }
}
