@import "./vars";

.container {
  width: 100%;
  background-color: $color-black-primary;

  .slider {
    position: relative;
    max-width: 1600px;
    margin: 0 auto;
    background-color: $color-black-primary;

    .slide {
      width: 100%;
      position: relative;

      .background {
        img {
          width: 100%;
        }
      }

      &--static {
        position: relative;
        background: rgb(228, 63, 64);
        background: linear-gradient(150deg,
            rgba(228, 63, 64, 1) 43%,
            rgba(202, 68, 67, 1) 43%,
            rgba(200, 68, 67, 1) 70%,
            rgba(188, 68, 67, 1) 71%);

        @include desktop {
          display: flex;
          height: auto;
        }

        .content {
          width: 100%;
          display: block;
          margin: 0 auto;
          background-color: black;
          height: 100%;

          @include desktop {
            width: 100%;
            margin: 0;
            max-width: 100%;
          }

          .bg1 {
            position: absolute;
            top: 0;
            left: 0;
            height: 45%;
            width: 100%;
            z-index: 1;
            display: flex;
            justify-content: center;
            border-bottom: solid 0.5px #fff;

            img {
              height: 70%;
              margin-top: 20px;

              @include desktop {
                display: none;
              }
            }
          }

          .bg2 {
            img {
              @include desktop {
                display: none;
              }
            }
          }

          .bg4 {
            position: absolute;
            left: 33%;
            bottom: -2%;
            height: 100%;
            z-index: 4;

            @media only screen and (max-width: 768px) {
              left: 37%;
              bottom: -6%;
            }

            @media only screen and (max-width: 480px) {
              bottom: -6%;
              left: 30%;
            }

            &.bg4-2 {
              top: -10%;
              bottom: inherit;
            }

            @include desktop {
              display: block;
              &.bg4-2 {
                top: 0;
                bottom: inherit;
              }
            }

            img {
              width: 100%;
              position: absolute;
              bottom: calc(50% + 45px);

              &.descImg {
                position: absolute;
                top: 47%;
                bottom: auto;
              }
            }

            .btn {
              position: absolute;
              bottom: 10%;

              @media only screen and (max-width: 768px) {
                button {
                  font-size: 15px;
                  height: initial;
                  padding: 5px;
                }
              }

              @include desktop {
                display: block;
              }
            }
          }

          .bg5 {
            display: none;

            @include desktop {
              display: flex;
              height: 100%;
              align-items: flex-end;
            }

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }

            &-1 {
              img {
                position: absolute;
                width: 65%;
              }
            }
          }

          .btn {
            position: absolute;
            bottom: 50%;
            z-index: 9;
            left: 5%;

            @include desktop {
              bottom: 80px;
              left: 25%;
              margin-left: -140px;
              display: none;
            }
          }
        }
      }

      &--dynamic {
        .background {
          width: 100;

          img {
            margin-bottom: -4px;
          }

          @include desktop {
            img {
              max-height: 42.2vw;
              object-fit: cover;
              object-position: center;
            }
          }

          @media screen and (min-width: 1600px) {
            max-height: 675px;
          }
        }

        .content {
          width: 100%;
          padding: 18px 40px;
          display: flex;
          align-items: center;
          justify-content: center;

          @include desktop {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            padding: 0 35px;
            display: block;
          }

          .overlay {
            overflow: hidden;
            width: 100%;
            position: absolute;
            height: 100%;
            z-index: -1;
            bottom: -20%;

            @include desktop {
              z-index: 0;
              margin-left: -35px;
              bottom: 0;
            }

            >div {
              width: 125%; // TODO: desktop
              height: 150%; // TODO: desktop
              left: -150%; // TODO: desktop
              top: -42%; // TODO: desktop
              transform: rotate(14deg); // TODO: desktop
              background-color: rgba(0, 0, 0, 0.3); // TODO: desktop
              position: absolute;
            }
          }

          .container {
            background-color: transparent;

            @include desktop {
              width: 50%;
              padding-left: 10px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              height: 100%;
            }

            @media screen and (min-width: 1100px) {
              width: 40%;
              padding-left: 30px;
            }

            @media screen and (min-width: 1300px) {
              padding-left: 60px;
            }

            .logo {
              width: 50%;
              display: block;
              margin: 0 auto;
              margin-bottom: 20px;
              height: 100px;
              position: relative;
              z-index: 1;

              img {
                width: auto;
                height: 100%;
                display: block;
                margin: 0 auto;
                max-width: 100%;
                object-fit: contain;
              }

              &--mobile {
                @include desktop {
                  display: none;
                }
              }

              &--desktop {
                display: none;

                @include desktop {
                  display: block;
                  transform: scale(0, 0);
                  margin: 0;

                  img {
                    margin: 20px 0;
                  }
                }
              }

              @media screen and (min-width: 1180px) {
                height: 180px;
              }
            }

            h2 {
              color: #fff;
              font-size: 20px;
              font-weight: 600;
              margin-bottom: 12px;
              text-transform: uppercase;
              position: relative;
              z-index: 1;

              @include desktop {
                font-size: 30px;
                opacity: 0;
              }

              @media screen and (min-width: 1100px) {
                font-size: 36px;
              }

              @media screen and (min-width: 1300px) {
                font-size: 42px;
              }
            }

            p {
              color: #fff;
              margin: 0;
              font-size: 18px;
              text-transform: uppercase;
              font-weight: 300;
              position: relative;
              z-index: 1;

              @include desktop {
                font-size: 22px;
                opacity: 0;
              }

              @media screen and (min-width: 1100px) {
                font-size: 26px;
              }

              @media screen and (min-width: 1300px) {
                font-size: 30px;
              }
            }
          }
        }

        &.active {
          .content {
            .overlay {
              @include desktop {
                >div {
                  animation-name: overlayAnimation;
                  animation-duration: 0.25s;
                  animation-timing-function: linear;
                  animation-fill-mode: forwards;
                  animation-iteration-count: 1;
                  z-index: 1;
                }
              }
            }

            .container {
              @include desktop {
                .logo {
                  &--desktop {
                    animation-name: zoonIn;
                    animation-duration: 0.3s;
                    animation-timing-function: linear;
                    animation-fill-mode: forwards;
                    animation-iteration-count: 1;
                  }
                }

                h2 {
                  animation-name: title;
                  animation-duration: 0.25s;
                  animation-timing-function: linear;
                  animation-fill-mode: forwards;
                  animation-iteration-count: 1;
                }

                p {
                  animation-name: subtitle;
                  animation-duration: 0.4s;
                  animation-timing-function: linear;
                  animation-fill-mode: forwards;
                  animation-iteration-count: 1;
                }
              }
            }
          }
        }
      }
    }
  }
}

.bg3 {
  background-color: #ffcd00;
  width: 100%;
  height: 65px;
  display: flex;
  justify-content: center;

  p {
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 31px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    margin: 0;
    color: #000000;
    margin-top: -5px;

    @include desktop {
      font-size: 22px;
    }
  }
}

@keyframes overlayAnimation {
  0% {
    left: -150%;
  }

  100% {
    left: -56%;
  }
}

@keyframes title {
  0% {
    opacity: 0;
    transform: translateY(80%);
  }

  20% {
    opacity: 0;
  }

  50% {
    opacity: 1;
    transform: translateY(0%);
  }

  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes subtitle {
  0% {
    opacity: 0;
    transform: translateY(80%);
  }

  20% {
    opacity: 0;
  }

  80% {
    opacity: 1;
    transform: translateY(0%);
  }

  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes zoonIn {
  0% {
    transform: scale(0, 0);
  }

  50% {
    transform: scale(0.4, 0.4);
  }

  100% {
    transform: scale(1, 1);
  }
}