@import './vars';

.pagination {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 14px 0;
  .page {
    margin: 0 5px;
    cursor: pointer;
    background-color: rgb(91 91 91 / 10%);
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 14px;
    color: blue;
    line-height: 0;
    &:hover {
      background-color: rgb(91 91 91 / 15%);
    }
    &.active {
      color: purple;
    }
  }
}