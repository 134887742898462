@import "./vars";

.videos {
  width: 100%;
  padding: 0;
  .card {
    background-color: #fff;
    padding: 1rem;
    margin-bottom: 1.3rem;
    @media screen and (min-width: 1100px) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
    }
    .content {
      width: 100%;
      @media screen and (min-width: 1100px) {
        width: 55%;
      }
      @media screen and (min-width: 1220px) {
        width: 50%;
      }
      .title {
        h3 {
          line-height: 31px;
          font-weight: bold;
          color: #c84443;
          margin: 0;
          font-size: 25px;
        }
      }
      p {
        font-size: 14px;
        color: rgb(119, 119, 119);
        line-height: 1.7em;
        font-weight: 400;
        display: block;
        margin: 0 auto;
        text-align: justify;
        &.long-title {
          color: #000;
          font-weight: bold;
        }
      }
      ul {
        padding: 0 12px;
        margin: 10px 0px;
      }
    }
    .video {
      width: 100%;
      margin-top: 1.3rem;
      @media screen and (min-width: 1100px) {
        width: calc(45% - 1.3rem);
      }
      @media screen and (min-width: 1220px) {
        width: calc(50% - 1.3rem);
      }
      .video-container {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 56.25%;
        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
