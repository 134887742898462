@use "sass:list";

$for-length: 30;

$font-weight: (400, 500, 600, 700, 800);
$font-size: (14, 16, 18, 20, 22, 24, 26, 28, 30);

// Breakpoints
$breakXl: 1200px;
$breakLg: 992px;
$breakMd: 768px;
$breakSm: 576px;
$breakXs: 425px;
$breakXxs: 375px;
$break0: 320px;

$breakpoints: (
  $breakXl,
  $breakLg,
  $breakMd,
  $breakSm,
  $breakXs,
  $breakXxs,
  $break0
);

$colors: (
  info: var(--info),
  black: var(--black),
  white: var(--white),
  orange: var(--orange),
  danger: var(--danger),
  success: var(--success),
  warning: var(--warning),
  infolight: var(--infolight),
  darkpurple: var(--darkpurple),
  orangelight: var(--orangelight),
  transparent: var(--transparent),
  dangerlight: var(--dangerlight),
  warninglight: var(--warninglight),
  successlight: var(--successlight),
  darkpurplelight: var(--darkpurplelight),
  gray50: var(--gray50),
  gray100: var(--gray100),
  gray200: var(--gray200),
  gray300: var(--gray300),
  gray400: var(--gray400),
  gray500: var(--gray500),
  gray600: var(--gray600),
  gray700: var(--gray700),
  gray800: var(--gray800),
  gray900: var(--gray900),
);

$valid-sides: top, bottom, left, right;
$spaces: join($valid-sides, (block, inline, auto, false));

$breakpoint-string: (
  $breakMd: "md\\:",
  $breakSm: "sm\\:",
  $breakXs: "xs\\:",
);
