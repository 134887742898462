@import './vars';

.news {
  position: relative;
  width: 100%;
  z-index: 1;
  .news-selected-box {
    @include desktop {
      display: none;
    }
  }
  .list {
    @include desktop {
      width: 275px;
      margin-left: auto;
      margin-right: 0;
      z-index: 2;
    }
  }
  .news-card {
    background-color: #fff;
    display: flex;
    align-items: center;
    border-bottom: solid 1px #e5e5e5;
    cursor: pointer;
    .image-box {
      width: 150px;
      padding: 0 10px;
      img {
        width: 100%;
      }
      @include desktop {
        width: 85px;
      }
    }
    .content-box {
      width: calc(100% - 150px);
      padding: 10px;
      h4 {
        font-size: 14px;
        color: #292b2c;
        line-height: 19px;
        font-size: inherit;
        font-size: medium;
        font-weight: 500;
        margin: 0;
      }
      p {
        color: $color-red-primary;
        text-align: end;
        font-size: 12px;
        margin: 5px 0 0;
      }
      @include desktop {
        width: calc(100% - 85px);
      }
    }
    &__result {
      display: none;
      @include desktop {
        display: none;
        position: absolute;
        width: calc(100% - 275px);
        left: calc(50% - 115.5px);
        max-height: 100%;
        overflow: auto;
        top: 0;
        opacity: 0;
        z-index: -1;
        cursor: default;
      }
    }
    &--active {
      @include desktop {
        background-color: #e5e5e5;
        .news-card__result {
          display: block;
          animation-name: cardshow;
          animation-duration: .25s;
          animation-timing-function:linear;
          animation-fill-mode:forwards;
          animation-iteration-count: 1;
          z-index: 1;
        }
      }
    }
  }
}
@keyframes cardshow {
  from {
    left: calc(50% - 115.5px);
    opacity: 0;
  }
  to {
    left: 0;
    opacity: 1;
  }
}

.news-selected {
  background-color: #e5e5e5;
  padding: 16px;
  h3 {
    margin: 0;
    font-size: 24px;
    line-height: 25px;
    font-weight: bold;
    color: #292b2c;
  }
  img {
    width: 100%;
    margin: 16px auto 0;
    max-width: 180px;
    display: block;
  }
  p {
    line-height: 1.8em;
    font-size: medium;
    text-align: justify;
    font-weight: 500;
    margin: 16px 0 0;
    color: rgb(119, 119, 119);
    &.date {
      color: $color-red-primary;
      text-align: end;
    }
  }
}