@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  src: local("Open Sans Light"), local("OpenSans-Light"),
    url(https://fonts.gstatic.com/s/opensans/v13/DXI1ORHCpsQm3Vp6mXoaTXhCUOGz7vYGh680lGh-uXM.woff)
      format("woff");
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans"), local("OpenSans"),
    url(https://fonts.gstatic.com/s/opensans/v13/cJZKeOuBrn4kERxqtaUH3T8E0i7KZn-EPnyo3HZu7kw.woff)
      format("woff");
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  src: local("Open Sans Semibold"), local("OpenSans-Semibold"),
    url(https://fonts.gstatic.com/s/opensans/v13/MTP_ySUJH_bn48VBG8sNSnhCUOGz7vYGh680lGh-uXM.woff)
      format("woff");
}

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@700;800&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap');

@import "./styles/generic.css";

* {
  font-family: "Open Sans";
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
}

.page-container {
  background: rgb(41, 43, 44);
  background: linear-gradient(
    150deg,
    rgba(41, 43, 44, 1) 56%,
    rgba(200, 67, 67, 1) 56%
  );
  width: 100%;
  min-height: 100vh;
  padding-bottom: 5px;
}

.slick-next {
  right: 10px !important;
}


.slick-slide img {
  object-fit: cover;
  width: 100%;
}

.slick-prev {
  left: 10px !important;
  z-index: 9 !important;
}

.App.modal-active {
  overflow: hidden;
  height: 100vh;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  display: none;
}
.modal--active {
  display: block;
}
.modal .overlay {
  background-color: rgba(41, 43, 44, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal .overlay .modal-card {
  width: 95%;
  max-width: 500px;
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  animation-name: modalCard;
  animation-duration: 0.25s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
}

.icon-img {
  width: 40px;
  max-width: 100%;
}
p {
  margin: 0;
}
input {
  width: 100%;
  background: #ffffff;
  border: 2px solid #cacaca;
  border-radius: 3px;
  font-weight: 600;
  font-size: 17px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: #767676;
  padding: 12px 14px;
  margin-bottom: 12px;
}

@keyframes modalCard {
  0% {
    opacity: 0;
    transform: translateY(80%);
  }
  20% {
    opacity: 0;
  }
  50% {
    opacity: 1;
    transform: translateY(0%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
