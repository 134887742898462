.relative {
  position: relative;
}

.width\:full {
  width: 100%;
}

.height\:full {
  height: 100%;
}


.width\:vw {
  width: 100vw;
}

.max\:width\:full {
  max-width: 100%;
}

.block {
  display: block;
}

.cursor\:default {
  cursor: default;
}

.cursor\:pointer {
  cursor: pointer;
}

@media only screen and (max-width:768px) {
  .md\:width\:full {
    width: 100%;
  }

  .md\:width\:vw {
    width: 100vw;
  }
}