@import './vars';

.header {
  width: 100%;

  .header-container {
    background-color: $color-black-primary;
    @include desktop {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__logo {
      padding-left: 30px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 80px;
      @include desktop {
        justify-content: flex-end;
        max-width: 360px;
      }
      @media screen and (min-width: 999px) {
        width: 360px;
      }
      img {
        width: 100%;
        max-width: 232px;

        @include desktop {
          max-width: 230px;
        }
      }
    }
    &__options {
      display: none;
      @include desktop {
        display: flex;
      }
      .option {
        width: 100%;
        &--auth {
          background-color: $color-red-primary;
        }
      }
    }
  }
}