@import './vars';

.container {
  background: rgb(41, 43, 44);
  background: linear-gradient(180deg, rgba(0,0,0,1) 73px, rgb(41, 43, 44) calc(50% - 73px));
  margin-bottom: -3px;
  padding-bottom: 3px;
  @include desktop {
    background: linear-gradient(180deg, rgba(0,0,0,1) 50%, rgb(41, 43, 44) 50%);
  }

  .container__desktop {
    @include desktop {
      display: flex;
      max-width: 1200px;
      margin: 0 auto;
      padding: 0 20px;
    }
  
    @media screen and (min-width: 1201px) {
      padding: 0;
    }
  }


  .banner {
    padding: 0 10px 32px;
    position: relative;
    z-index: 2;
    width: 100%;
    @include desktop {
      width: 33.333%;
      padding: 0;
    }
    &__header {
      display: flex;
      background-color: $color-red-primary;
      width: 100%;
      height: 63px;
      align-items: center;
      justify-content: center;
      margin-top: -12px;
      position: relative;

      h3 {
        margin: 0;
        font-weight: 700;
        font-size: 20px;
        line-height: 27px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #FFFFFF;
        position: relative;
        z-index: 2;
        @include desktop {
          font-size: 15px;
        }
        @media screen and (min-width: 999px) {
          font-size: 20px;
        }
      }
      .arrow {
        width: 33px;
        height: 33px;
        background-color: $color-red-primary;
        transform: rotate(45deg);
        position: absolute;
        bottom: -5px;
        left: calc(50% - 16.5px);
        z-index: 1;
      }
    }
    &__content {
      position: relative;
      width: 100%;
      height: 0;
      padding-top: 68%;
      img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
}