@import './vars';

.reviews {
  width: 100%;
  background-color: #fff;
  padding: 20px;
  .divider {
    width: calc(100% + 42px);
    margin-left: -21px;
    height: 1px;
    background-color: #DCDCDC;
  }
  .totals {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 21px;
    .texts {
      display: flex;
      p {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        color: #000000;
        margin: 0 4px;
      }
    }
    .stars {
      display: flex;
      img {
        width: 17px;
        margin-left: 2px;
      }
    }
  }
  .review {
    .comment {
      p {
        margin: 14px 0 0;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #5B5B5B;
      }
    }
    .name {
      display: flex;
      align-items: center;
      margin: 12px 0 14px;
      p {
        font-style: normal;
        font-weight: 700;
        font-size: 13px;
        line-height: 16px;
        margin: 0;
        color: #000000;
        margin-right: 5px;
      }
      .stars {
        display: flex;
        img {
          width: 13px;
          margin-left: 2px;
        }
      }
    }
  }
}