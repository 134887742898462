@use "sass:map";
@use "sass:string";
@import "./variables";

// MediaQueries max-width
@mixin mediaQueriesXl {
  @media only screen and (max-width: $breakXl) {
    @content;
  }
}

@mixin mediaQueriesLg {
  @media only screen and (max-width: $breakLg) {
    @content;
  }
}

@mixin mediaQueriesMd {
  @media only screen and (max-width: $breakMd) {
    @content;
  }
}

@mixin mediaQueriesSm {
  @media only screen and (max-width: $breakSm) {
    @content;
  }
}

@mixin mediaQueriesXs {
  @media only screen and (max-width: $breakXs) {
    @content;
  }
}

@mixin mediaQueriesXxs {
  @media only screen and (max-width: $breakXxs) {
    @content;
  }
}

@mixin mediaQueries0 {
  @media only screen and (max-width: $break0) {
    @content;
  }
}

// Weight
@mixin fontWeight($weight) {
  font-weight: ($weight) * 100;
}

@mixin fontLineHeight($i, $size) {
  font-size: #{$size}px;
  line-height: calc(var(--line-height-16) + #{4px * $i});
}

@mixin createFontWithLineHeight($breakpoint) {
  @for $i from 1 through length($font-size) {
    $size: nth($font-size, $i);
    $font-var: "font\\:#{$size}";

    @if $breakpoint==$breakMd {
      .md\:#{$font-var} {
        @include fontLineHeight($i, $size);
      }
    }

    @else if $breakpoint==$breakSm {
      .sm\:#{$font-var} {
        @include fontLineHeight($i, $size);
      }
    }

    @else {
      .#{$font-var} {
        @include fontLineHeight($i, $size);
      }
    }
  }
}

@mixin createSchemas() {

  @each $variante,
  $color in $colors {
    $color-var: unquote("color\\:#{$variante}");

    .#{$color-var} {
      color: $color;
    }

    .bg\:#{$color-var} {
      background-color: $color;
    }
  }
}

@mixin baseSpaces($base, $break, $value) {
  $_base-variant: $base;

  @if $break {
    $_base-variant: unquote("#{$break}#{$base}");
  }

  @each $space in $spaces {
    $_spc: $base;
    $_variant: unquote(".#{$_base-variant}\\:#{$value}");

    @if $space {
      $_spc: unquote("#{$base}-#{$space}");
      $_variant: unquote(".#{$_base-variant}\\:#{$space}\\:#{$value}");
    }

    #{$_variant} {
      #{$_spc}: #{$value}px;
    }
  }
}

@mixin makerSpaces($breakpoint, $value) {
  $break: map.get($breakpoint-string, $breakpoint);
  @include baseSpaces(margin, $break, $value);
  @include baseSpaces(padding, $break, $value);
}

// We manufacture all the properties related to the flexbox
@mixin makerFlexbox($breakpoint) {
  $flex: generate_classes("flex", $breakpoint);
  $self: generate_classes("self", $breakpoint);
  $items: generate_classes("items", $breakpoint);
  $justify-var: generate_classes("justify", $breakpoint);

  @each $direction in $flex-direction {
    $variant-direction: unquote("#{$flex}#{$direction}");

    #{$variant-direction} {
      flex-direction: $direction;
    }
  }

  @each $wrap in $flex-wrap {
    $variant-wrap: unquote("#{$flex}#{$wrap}");

    #{$variant-wrap} {
      flex-wrap: $wrap;
    }
  }

  @each $selfs in $flex-self {
    $variant-self: unquote("#{$self}#{$selfs}");

    #{$variant-self} {
      align-self: $selfs;
    }
  }

  @each $_items in $flex-items {
    $variant-items: unquote("#{$items}#{$_items}");

    #{$variant-items} {
      align-items: $_items;
    }
  }

  @each $justify in $flex-justify {
    $variant-justify: unquote("#{$justify-var}#{$justify}");
    $withSpace: map.get($flex-space, $justify);

    @if $withSpace {
      $variant-justify: unquote("#{$justify-var}#{$withSpace}");
    }

    #{$variant-justify} {
      justify-content: $justify;
    }
  }
}

@mixin makerStyled($breakpoint) {
  @include makerFlexbox($breakpoint);

  $gap: generate_classes(gap, $breakpoint);
  $radius: generate_classes("around", $breakpoint);

  @for $i from 1 through calc($for-length) {
    $results: #{$i * 2};
    $variant-gap: unquote("#{$gap}#{$results}");
    $variant-radius: unquote("#{$radius}#{$results}");

    #{$variant-radius} {
      border-radius: unquote("#{$results}px");
    }

    #{$variant-gap} {
      gap: calc(unquote("#{$i}rem") / 2);
    }
  }
}