.font\:12 {
  font-size: calc(var(--fontSize) - 2px);
  line-height: var(--line-height-16);
}

.text\:center {
  text-align: center;
}

.text\:left {
  text-align: left;
}

.text\:balance {
  text-wrap: balance;
}

.text\:right {
  text-align: right;
}

.text\:underline {
  text-decoration-line: underline;
}

.text\:uppercase {
  text-transform: uppercase;
}

@include createFontWithLineHeight($breakXl);

@for $weight from 4 through 8 {
  .font\:weight\:#{$weight * 100} {
    @include fontWeight($weight);
  }
}

@include mediaQueriesMd() {
  @include createFontWithLineHeight($breakMd);
}

@include mediaQueriesSm() {
  @include createFontWithLineHeight($breakSm);
}
