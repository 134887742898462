@use "sass:list";

$flex-direction: (row, row-reverse, column, column-reverse);
$flex-wrap: (nowrap, wrap, wrap-reverse);

$flex-global: (inherit, initial, revert, revert-layer, unset);
$flex-basic: (normal, stretch);
$flex-base: join($flex-global, $flex-basic);

$flex-positional: (
  end,
  start,
  center,
  flex-end,
  self-end,
  flex-start,
  self-start
);
$flex-overflow: ("safe center", "unsafe center");
$flex-variants: (normal, auto, flex-start, flex-end, center);
$flex-justified: (left, right, space-between, space-around, space-evenly);
$flex-space: (
  space-between: "between",
  space-around: "around",
  space-evenly: "evenly",
);

$flex-self: join($flex-base, $flex-positional);
$flex-items: join($flex-base, join($flex-positional, baseline));
$flex-justify: join($flex-base, join($flex-positional, $flex-justified));

.flex {
  display: flex;
  flex-direction: row;
}

.flex\:1 {
  flex: 1 1;
}

.flex\:column-reverse {
  flex-direction: column-reverse;
}
