@import './vars';

.container {
  width: 100%;
  margin-top: -15px;
  @include desktop {
    width: 100%;
    margin: 0 auto;
    display: flex;
    max-width: 900px;
    align-items: center;
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;

    >div {
      flex-grow: 1;
    }
  }
  .casino {
    width: 100%;
    margin: 0 auto;
    max-width: 450px;
    img {
      width: 100%;
    }
    @include desktop {
      width: 40%;
      max-width: auto;
      img {
        width: 105%;
      }
    }
  }
  .games {
    width: 100%;
    margin: 0 auto;
    max-width: 550px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 9px;
    @include desktop {
      width: 60%;
      max-width: auto;
      margin-top: 5%;
    }
    .game-card {
      background: #C74242;
      border-radius: 16px;
      flex-grow: 1;
      max-width: calc(50% - 5px);
      width:  calc(50% - 5px);
      overflow: hidden;
      margin: 0 0 10px;
      cursor: pointer;
      @include desktop {
        margin: 0 0 20px;
        max-width: calc(50% - 10px);
        width:  calc(50% - 10px);
      }
      &__header {
        height: 84px;
        background-position: center;
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;
        h3 {
          color: #FFFFFF;
          font-size: 25px;
          text-align: center;
          text-shadow: 0px 2px 2px #000000;
        }
        @include desktop {
          height: 140px;
        }
      }
      &__footer {
        background: rgb(228,63,64);
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0 9px;
        height: 24px;
        background: linear-gradient(150deg, rgba(228,63,64,1) 43%, rgba(202,68,67,1) 43%, rgba(200,68,67,1) 70%, rgba(188,68,67,1) 71%);
        span {
          color: #fff;
          font-weight: 600;
          font-size: 16px;
          text-transform: capitalize;
        }
        img {
          height: 13px;
          margin-left: 10px;
          margin-right: 5px;
        }
      }
    }
  }
}