.crypto-hero {
  display: flex;
  flex-direction: column;

  position: relative;
  background-color: #E4EEF5;
  gap: 1rem;

  &.wrap {
    max-width: 700px;
    padding: 2rem;
    margin-left: 10rem;

    @include mediaQueriesXl {
      max-width: 100%;
      margin-left: 0;
    }

    &::before {
      content: "";
      border-top: 20px solid #E4EEF5;
      border-bottom: 20px solid transparent;
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      position: absolute;
      bottom: -15%;
      left: 20%;

      @include mediaQueriesXl {
        bottom: -12%;

      }

    }
  }

  h2 {
    color: #1E1F21;
    font-size: 46px;
    font-weight: 800;
    line-height: 46px;
    margin: 0;

    @include mediaQueriesMd {
      font-size: 26px;
      line-height: 26px;
    }

    span {
      color: #E43F40;
    }
  }

  &_content {
    gap: 1rem;
    display: flex;
    flex-direction: column;
    color: #1E1F21;

    @include mediaQueriesMd {
      gap: 10px;

    }

    p {
      font-size: 18px;
      font-weight: 700;
      line-height: 24.51px;

      @include mediaQueriesMd {
        font-size: 14px;

      }
    }

    a {
      color: #E43F40;

    }
  }
}