@import "./vars";

.footer {
  background-color: $color-black-primary;
  padding: 20px 50px;
  .content {
    max-width: 1300px;
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    p {
      margin: 0;
      color: #fff;
      font-size: 12px;
      &.title {
        font-size: 14px;
        margin-bottom: 5px;
        margin-right: 40px;
      }
    }
  }

  .country {
    display: flex;
    align-items: center;
    span {
      margin: 0;
      color: #fff;
      font-size: 12px;
      padding-right: 10px;
    }
  }
}
