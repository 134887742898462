@import './vars';

.button {
  max-width: calc(50% - 8px);
  width: 193px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid  $color-black-primary;
  border-radius: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 29px;
  text-transform: uppercase;
  cursor: pointer;

  @include desktop {
    width: 160px
  }

  &--primary {
    background-color: $color-red-primary;
    color: $color-black-primary;
  }

  &--secondary {
    background-color: $color-black-primary;
    color: $color-red-primary;
    border-color: $color-red-primary;
  }

  &--banner {
    background: $color-red-primary;
    border: 1px solid #fff;
    border-radius: 10px;
    width: 165px;
    max-width: 100%;
    color: #fff;
    font-weight: 700;
  }
}