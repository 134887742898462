@import './vars';

.sidebar {
  padding: 1.3rem 0;
  @include desktop {
    padding: 0;
  }
  &-item {
    margin-bottom: 1.3rem;
    &__title {
      background-color: $color-red-primary;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 44px;
      h2 {
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 20px;
        display: flex;
        align-items: center;
        text-align: center;
        margin: 0;
        text-transform: uppercase;
        color: #FFFFFF;
      }
      &--dark {
        background-color: #000000;
      }
    }
    &__content {
      background-color: #fff;
      padding: 21px;
      &--full {
        padding: 0;
        position: relative;
      }
      &.countdown {
        .time {
          width: 100%;
          display: flex;
          justify-content: center;
          div {
            width: 50px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            span {
              font-weight: 700;
              font-size: 27px;
              line-height: 37px;
              display: flex;
              align-items: center;
              text-align: center;
              color: #000000;
              &.label {
                font-size: medium;
                font-weight: 500;
                color: rgb(119, 119, 119);
              }
            }
          }
        }
        .date-info {
          display: flex;
          justify-content: space-between;
          padding-top: 30px;
          .item {
            display: flex;
            justify-content: center;
            align-items: center;
            span {
              padding-left: 5px;
              font-size: 11px;
              color: rgb(119, 119, 119);
            }
          }
        }
        .description {
          padding-top: 10px;
          font-size: 14px;
          color: rgb(119, 119, 119);
        }
      }
      p {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #5B5B5B;
        margin: 0;
      }
    }
  }
}