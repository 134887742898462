@import "../../styles/config/abstracts/mixins";
@import "./hero.scss";

.highlight {
  color: #E43F40;
  font-weight: 800;
}

.crypto {
  display: flex;
  flex-direction: column;

  &_hero {
    display: flex;
    width: 100%;
    background-color: #1E1F21;

    @include mediaQueriesXl {
      background-color: #000;
    }

    .crypto-logo {
      position: absolute;
      right: 0;
      width: 650px;
      z-index: 10;

      @include mediaQueriesXl {
        display: none;
      }

      >img {
        width: 100%;
        height: 100%;
        flex: 1;
      }
    }

    &__divider {
      width: 100%;
    }

    &__content {
      position: relative;
      display: flex;
      overflow: hidden;
      align-items: center;
      flex-direction: column;

      margin: 3rem;
      max-width: max-content;

      @include mediaQueriesXl {
        max-width: 100%;
        margin: 0;
        padding: 2rem;
      }

      h2 {
        color: #FFFFFF;
        font-size: 80px;
        font-weight: 800;
        line-height: 68px;
        letter-spacing: -0.03em;
        margin: 0;
        text-align: center;
        text-transform: uppercase;

        @include mediaQueriesMd {
          font-size: 36px;
          line-height: 30px;
        }

        @include mediaQueriesXs {
          margin-bottom: 50px;
        }

        span {
          color: #E43F40;
        }
      }

      img[alt=faster-payouts] {
        position: relative;
        width: 100%;
        height: 70px;
        top: -1rem;

        @include mediaQueriesXs {
          top: calc(100% - 9rem);
          position: absolute;
          flex: 1;
          width: 140%;
        }
      }

      >button {
        color: #FFFFFF;
        font-size: 26px;
        font-weight: 700;
        line-height: 35.41px;
        text-align: center;
        border-radius: 44px;
        background-color: #000000;
        padding-inline: 40px;
        border: 2px solid #FFF;

        @include mediaQueriesSm {
          font-size: 18px;
          width: 100%;
        }
      }
    }

    &__footer {
      background-color: #CFDBE3;

      &_content {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        gap: 1rem;
        padding: 2rem 3rem;
        max-width: 700px;

        @include mediaQueriesSm {
          padding: 1rem 2rem;

          >img {
            max-width: 100%;
          }

        }

        @include mediaQueriesXl {
          max-width: 100%;
        }

        .bonus {
          color: #000000;
          background-color: #D9D9D9;
          border-radius: 37px;
          padding: 1rem;
          font-size: 19px;
          font-weight: 600;
          line-height: 15.01px;
          text-align: center;

          @include mediaQueriesXl {
            font-size: 12px;
            padding: 0.5rem 1rem;

          }
        }
      }
    }
  }

  &_steps {
    display: flex;
    flex-direction: column;
  }

  &_exchange {
    background-color: #292B2C;
    background-image: url("../../../public/assets/img/choose-exhange.svg");
    background-repeat: no-repeat;
    background-position: center right;
    width: 100%;

    margin-top: 3rem;

    @include mediaQueriesLg {
      background-image: none;
    }

    h2 {
      margin: 0;
      color: #FFFFFF;
      font-size: 24px;
      font-weight: 700;
      line-height: 32.68px;

      span {
        color: #E43F40;
      }
    }

    &__content {
      max-width: 450px;
      margin: 3.5rem 9rem;

      @include mediaQueriesLg {
        max-width: 100%;
        margin: 0;
        padding: 2rem;
      }
    }

    .exchange-wrap_img {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
      gap: 2rem;
      max-width: 75%;

      @include mediaQueriesLg {
        max-width: 100%;
        gap: 2rem 1rem;

      }
    }

    .exchange-img {
      background-color: #FFF;
      border-radius: 7px;
      height: 43px;
      padding: 5px 20px;
    }

    p {
      font-size: 18px;
      font-weight: 700;
      line-height: 24.51px;
      color: #FFFFFF;

      @include mediaQueriesLg {
        font-size: 16px;
      }
    }

    button {
      border: none;
      border-radius: 10px;
      padding: 10px 20px 10px 20px;
      background-color: #E43F40;
      color: #FFFFFF;
      font-size: 21px;
      font-weight: 700;
      line-height: 28.6px;

      width: max-content;
    }
  }

  &_footer {
    padding: 3rem;
    background-color: #E4EEF5;

    h4 {
      margin: 0;
      color: #E43F40;
      font-size: 24px;
      font-weight: 700;
      line-height: 32.68px;
    }

    p {
      max-width: 300px;
      margin: auto;
      text-align: center;
    }
  }
}