@import "../../../styles/vars.scss";
@import "../../../styles/payment-methods.module.scss";
@import "../../../styles/modal.module.scss";

.landing {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: 40px 163px 172px 265px 50px repeat(2, 1fr);
  background-color: #1c1e1f;

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: 40px 263px 1fr 230px 188px 170px 260px 350px;
  }

  @media screen and (max-width: 480px) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: 40px 263px 1fr 80px 188px 370px 530px 600px;
  }
}

.lg-container {
  max-width: 1200px;
  width: 100%;
  margin: auto;
}

.payments-containers {
  // width: 100%;
  display: flex;

  .icons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 10px;
    gap: 10px;

    .icon {
      display: flex;

      img {
        border-radius: 50%;
        height: 22px;
        width: 22px;
      }
    }
  }
}

.lg-form {
  position: relative;
  z-index: 2;
  background-color: #000;
  box-shadow: 0 0 5px -1px rgba(255, 255, 255, 0.4);

  max-width: 320px;
  padding: 16px;
  grid-column: -4 / 2 span;
  grid-row: 1 / 6 span;

  @media screen and (max-width: 768px) {
    grid-column: 1 / -1;
    grid-row: 3 / 3;
    max-width: 95%;
    margin-inline: auto;
    margin-block: -30px;
  }

  .form {
    background-color: #000;
    width: 100%;

    form {
      width: 100%;
    }

    .captcha {
      margin: 20px 0;
      max-width: 100%;
      overflow: hidden;
    }

    ul {
      padding: 0;
      margin: -9px 0 10px 5px;

      li {
        font-size: 9px;
        margin-bottom: 2px;
      }
    }

    .errorInput {
      input {
        border-color: #e43f40;
      }

      ul li {
        color: #e43f40;
      }
    }

    button {
      background: #e43f40;
      border-radius: 10px;
      width: 100%;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      font-size: 21px;
      line-height: 29px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #ffffff;
      border: 0;
      outline: none;
      height: 53px;

      &.cancel {
        margin: 10px 0;
        background-color: $color-gray-primary;
      }

      &.disabled {
        opacity: 0.5;
      }
    }

    .note {
      color: #bdbdbd;
      font-family: Montserrat;
      font-size: 9px;
      font-style: normal;
      font-weight: 600;
      line-height: 9.5px;
    }

    .checkbox {
      display: flex;
      align-items: center;
      width: 100%;
      margin-bottom: 12px;

      input {
        width: 12px;
        height: 12px;
        margin: 0;
      }

      label {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        display: flex;
        align-items: center;
        margin: 0;
        margin-left: 10px;
        color: #767676;

        span {
          color: #00a3ff;
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          margin-left: 4px;
        }
      }
    }

    a {
      color: #ffcd00;
      font-family: Montserrat;
      font-size: 9px;
      font-style: normal;
      font-weight: 600;
      line-height: 9.5px;
      text-decoration-line: underline;
    }
  }
}

.lg-form-footer {
  grid-row: 7;
  grid-column: -4 / 2 span;
  max-width: 320px;
  height: fit-content;

  background-color: #ff3e3a;

  @media screen and (max-width: 768px) {
    grid-column: 1 / -1;
    grid-row: 5;
    max-width: 100%;
  }

  &_header {
    min-height: 150px;
    padding: 12px;
    display: flex;
    align-items: center;
    background: url("../../../../public/assets/img/landing/landing.1.png"),
      rgba(0, 0, 0, 0.2) 50% / cover no-repeat;

    @media screen and (max-width: 768px) {
      background-position: right;
      background-repeat: no-repeat;
    }

    h3 {
      margin: 0;
      color: #fff;
      font-family: Montserrat;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 100%;
      text-transform: uppercase;

      ~ h3 {
        color: #000;
      }
    }

    p {
      color: #000;
      margin-top: 4px;
      font-family: Montserrat;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 129.214%;
      max-width: 80%;
    }
  }

  &_sign {
    position: relative;
    padding: 10px;

    ::before {
      content: "";
      position: absolute;
      left: 10px;
      height: calc(100% - 20px);
      width: 7px;
      background: #000;
    }

    p {
      padding-left: 20px;
      color: #fff;
      font-family: Montserrat;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
}

.lg-header {
  background-color: #1c1e1f;
  grid-column: 1 / -1;
  grid-row: 1;
  height: 100%;
}

.lg-hero {
  background: url("../../../../public/assets/img/lg-hero.png"),
    #1c1e1f 50% / cover no-repeat;
  grid-column: 1 / -1;
  grid-row: 2;
  background-repeat: no-repeat;

  @media screen and (max-width: 768px) {
    padding: 10px;
    min-height: auto;
    background-size: cover;
    background-position: center;
  }

  :where(h1, h2, h3) {
    margin: 0;
    display: inline;
    color: #fff;
    text-shadow: 0px 0px 60px rgba(0, 0, 0, 0.6),
      0px 0px 9.600000381469727px rgba(0, 0, 0, 0.8);
    font-family: Montserrat;
    font-size: 38px;
    font-style: normal;
    font-weight: 800;
    line-height: 72%;
    /* 45.36px */
    letter-spacing: -3.15px;
  }

  hgroup {
    padding-top: 40px;
  }

  :where(h1, h2) {
    font-style: normal;
    font-weight: 800;
    letter-spacing: -2.45px;
    line-height: 60px;
  }

  .hg-flex {
    display: flex;
    gap: 10px;

    @media screen and (max-width: 480px) {
      flex-wrap: wrap;
    }
  }

  h1 {
    font-size: 60px;
  }

  h2 {
    color: #ff3e3a;
    font-size: 49px;
  }

  @media screen and (max-width: 480px) {
    h1,
    h2 {
      font-size: 60px;
    }
  }

  h3 ~ div {
    span {
      color: #ff3e3a;
      font-size: 38px;
      font-style: normal;
      font-weight: 450;
      line-height: 27.5px;
      letter-spacing: -1.52px;
    }
  }
}

.lg-hero-mid {
  grid-column: 1 / -1;
  grid-row: 3;
  background: #000;
  display: flex;

  @media screen and (max-width: 768px) {
    grid-row: 6;
    padding: 20px;
    height: fit-content;
  }

  h3 {
    color: #ff3e3a;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    margin: 0;
    margin-bottom: 8px;
    text-transform: uppercase;
  }

  &_flex {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }

  &_card {
    position: relative;
    min-height: 90.636px;
    max-width: 206px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #1c1e1f;
    border-radius: 13px;
    overflow: hidden;

    @media screen and (max-width: 480px) {
      max-width: 100%;
    }

    p.card-title {
      position: relative;
      z-index: 2;
      color: #fff;
      padding-left: 30%;

      font-family: Montserrat;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: 21.5px;
      /* 97.727% */
      text-transform: uppercase;

      ~ p {
        color: #ff3633;
        text-align: center;
        padding-left: 40px;

        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
      }

      span {
        color: #ff3e3a;
      }
    }

    img {
      position: absolute;
      left: 0;
    }
  }
}

.lg-mid-container {
  background: #292b2c;
  width: 100%;

  grid-column: 1 / -1;
  grid-row: 4;

  padding: 10px;

  @media screen and (max-width: 768px) {
    grid-row: 7;
  }

  @media screen and (max-width: 480px) {
    padding: 0;
  }

  .lg-mid-wrap {
    display: flex;
    gap: 40px;
    margin-left: 5%;

    @media screen and (max-width: 768px) {
      margin-left: 0;
      justify-content: center;
      flex-wrap: wrap;
    }
  }

  .lg-mid-including {
    @media screen and (max-width: 480px) {
      background: #1c1e1f;
      flex: 1;
      padding: 20px;
    }

    h3 {
      max-width: 206px;
      color: #ff3e3a;
      font-family: Montserrat;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
      opacity: 0.8;

      @media screen and (max-width: 480px) {
        max-width: 100%;
      }
    }

    ul {
      list-style-image: url("../../../../public/assets/img/icons/icon-arrow.svg");

      li {
        color: #fff;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 29.5px;
      }
    }
  }

  .lg-mid-crypto {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    max-width: 283.129px;
    border-radius: 13px;
    background: #1c1e1f;

    h3 {
      color: #fff;
      text-align: center;
      font-family: Montserrat;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    img {
      width: 50px;
    }

    span {
      color: #ffcd00;

      font-family: Montserrat;
      margin-left: 8px;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
}

.lg-mid-level-container {
  background: #ffcd00;
  width: 100%;

  grid-column: 1 / -1;
  grid-row: 5;

  padding: 10px;

  p {
    color: #000;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    max-width: 480px;
    margin-left: 7%;

    span {
      color: #ff3633;

      font-family: Montserrat;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
    }
  }

  @media screen and (max-width: 768px) {
    grid-row: 4;

    > div {
      display: flex;
      justify-content: center;
      align-items: center;
      height: -webkit-fill-available;
    }

    p {
      font-size: 20px;
      margin-left: 0;
      text-align: center;

      span {
        font-size: 20px;
      }
    }
  }
}

.lg-footer-mid-container {
  background: #292b2c;
  grid-column: 1 / -1;
  grid-row: 6 / 2 span;

  padding: 10px;

  @media screen and (max-width: 768px) {
    grid-row: 8;
  }

  .cards-perks-wraps {
    max-width: 55%;

    @media screen and (max-width: 768px) {
      max-width: 100%;
    }

    :where(h3, h4) {
      margin: 0;
      font-family: Montserrat;
      font-style: normal;
      color: #fff;
    }

    > h3 {
      color: #e43f40;
      text-align: center;
      font-size: 24px;
      margin-block: 20px;
      font-weight: 700;
      line-height: 33.5px;
      /* 139.583% */
      text-transform: uppercase;
      opacity: 0.8;
    }

    > h4 {
      text-align: center;
      font-size: 18px;
      font-weight: 600;
      margin-block: 20px;
      margin-top: 40px;
      line-height: normal;
    }
  }

  .cards-perks-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 20px;

    .card-perks {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;

      border-radius: 11px;
      // background: #CA393A;
      max-width: 319.069px;
      width: 100%;
      padding: 15px;
      height: 74px;
      // box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

      background-size: cover;
      background-repeat: no-repeat;

      &:nth-child(1) {
        background-image: url("../../../../public/assets/img/landing/Rectangle.1.png");
      }

      &:nth-child(2) {
        background-image: url("../../../../public/assets/img/landing/Rectangle.4.png");
      }

      &:nth-child(3) {
        background-image: url("../../../../public/assets/img/landing/Rectangle.2.png");
      }

      &:nth-child(4) {
        background-image: url("../../../../public/assets/img/landing/Rectangle.3.png");
      }

      @media screen and (max-width: 768px) {
        max-width: 49%;
        height: 90px;
      }

      @media screen and (max-width: 480px) {
        max-width: 100%;
        height: 80px;
      }

      @media screen and (min-width: 480px) {
        &:nth-child(3) > div {
          max-width: 230px;
        }
      }

      h3 {
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        font-size: 44px;
        font-weight: 600;
        line-height: 111.539%;
      }

      h4 {
        color: #fff;
        font-size: 18px;
        font-weight: 700;
        line-height: 114%;
        text-transform: uppercase;
      }

      p {
        color: #fff;

        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%;

        @media screen and (max-width: 480px) {
          font-size: 22px;
        }

        span,
        ~ p {
          color: #ffcd00;

          text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.35);
          font-family: Montserrat;
          font-size: 10px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;

          @media screen and (max-width: 480px) {
            font-size: 16px;
          }
        }
      }
    }
  }
}

.lg-footer {
  background: #000;
  grid-column: 1 / -1;
  grid-row: -1;

  padding: 10px;

  .footer-description {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 30px;

    ~ div {
      justify-content: flex-start;
      padding: 0;
      height: auto;
    }

    @media screen and (max-width: 480px) {
      padding: 30px;

      ~ div {
        justify-content: center;
      }
    }

    p.footer-about {
      font-weight: 700 !important;

      a {
        color: #fff;
        display: block;
      }
    }

    p {
      color: #fff;

      font-family: Montserrat;
      font-size: 10px;
      font-weight: 500;
      font-style: normal;
      line-height: normal;

      @media screen and (max-width: 480px) {
        font-size: 16px;
        text-align: center;
      }
    }
  }
}
